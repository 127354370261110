import React from "react";
import { Link } from "react-router-dom";

export default function PricingSection2() {
  return (
    <div className="PricingSection2">
      <div className="container">
        <div className="PS2__content">
          <div className="PS2__subcontent">
            <h4>
              Interested in Physical class(Classroom) in Abuja Nigeria? <a href="https://reworkacademy.co/courses/full-stack-web-development-nodejs">Join Now</a>
            </h4>
          </div>
          <h2>Want to Learn more about our courses?</h2>
          <p>Our courses are carefully designed and structured to deliver the best quality to take you from beginner to advanced skill level.</p>
          <div className="PS2__content-links">
            <Link to="/course/details" className="PS2__content-link1">
              Browse our courses
            </Link>
            <a href="https://register.reworkacademy.co/" className="PS2__content-link2">
              Apply Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
