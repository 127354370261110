import React from "react";
import { Link } from "react-router-dom";

function HomeSection1() {
  return (
    <div className="home_section1">
      <div className="hs1--content grid1 container">
        <div className="hs1--left">
          <h3>We are making an impact on the future of work.</h3>
        </div>
        <div className="hs1--right">
          <p>
            Rework Academy is not like other educational institutions. We are a bootcamp for people who want to learn tech skills and start a career in tech. We focus on
            giving you Job Ready Skills, that gives you the opportunity to grow your career faster.
          </p>
          <p>
            Our comprehensive curriculum, which combines theoretical knowledge with Practical and project-based learning, Collaborative learning, and Mentorship to give a
            real-world experience, guarantees that anyone can become a top Software Engineer.
          </p>
          <Link to="/about" className="btns-primary">
            Learn more
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomeSection1;
