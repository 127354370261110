export default function PricingBanner() {
  return (
    <div className="pricing_banner">
      <div className="space-top container">
        <div className="P__banner-content">
          <h2>Flexible Tuition plan for high-quality tech education</h2>
          <p>Opt in for our monthly based payment plan</p>
          <a href="https://register.reworkacademy.co/">Apply Now</a>
        </div>
      </div>
    </div>
  );
}
