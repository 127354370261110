import checkbox from "../../assets/images/checkbox.png";

function CDSection3() {
  return (
    <div className="cd_section-content">
      <div className="CD--overview">
        <h2>Learn at your Own Pace</h2>
        <p className="CD--overview-text1">
          Rework’s Full Stack Web Development program is an online self-pace course, that adjusts to your schedule and your pace. It combines Practical and Project-based
          learning, Collaborative learning, and Mentorship to give a real-world experience, and personalised support, to support you on your journey. You get tech
          education that truly works. We work with you to complete the course on time.
        </p>
      </div>
      <div className="cd_s2--list cd_s2--list2">
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>Choose from range of live classes</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>You have access to pre-recorded lessons</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>Work with a team to develop real products, on your schedule</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>Tap into academic and career support at anytime</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>Work through the program at your own pace with Rework to guide you</p>
        </div>
      </div>
    </div>
  );
}

export default CDSection3;
