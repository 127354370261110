import { Link } from "react-router-dom";
import "../assets/styles/ErrorPage.css";

function ErrorPage() {
  return (
    <div className="error_page">
      <div className="error_content">
        <h3>404</h3>
        <p>Page not Found</p>
        <Link to="/">Go Home</Link>
      </div>
    </div>
  );
}

export default ErrorPage;
