import img1 from "../../assets/images/project_based_learning.png";
import img2 from "../../assets/images/peer_learning.png";
import img3 from "../../assets/images/mentoring.png";
import { FaArrowsAltH } from "react-icons/fa";
function HomeFocus() {
  return (
    <div className="home_focus">
      <div className="home_focus--grid">
        <div className="home_focus-gridContent">
          <h3>Project based Learning</h3>
          <img src={img1} alt="Project Based Learning" />
          <FaArrowsAltH className="home_focus_icon" />
        </div>
        <div className="home_focus-gridContent">
          <h3>
            Peer Learning <span>(Collaboration)</span>
          </h3>
          <img src={img2} alt="Peer Learning" />
          <FaArrowsAltH className="home_focus_icon" />
        </div>
        <div className="home_focus-gridContent">
          <h3>Mentoring</h3>
          <img src={img3} alt="Mentoring" />
        </div>
      </div>
    </div>
  );
}

export default HomeFocus;
