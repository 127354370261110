import React from "react";

export default function AboutBanner() {
  return (
    <div className="space-top container">
      <div className="aboutBanner">
        <h2>
          Providing world-class training to Africa's rapidly expanding youth population in order to close the continent's skills gap and lower unemployment in the Tech
          sector
        </h2>
        <p>Deliberately designed to equip you with the skills and knowledge required to launch your tech career.</p>
      </div>
    </div>
  );
}
