import React from "react";

function AboutSect2() {
  return (
    <div className="container space-top">
      <div className="a__sect2">
        <h4>Our Values</h4>
        <h2>We believe there are enough hands in Africa to fill the global tech skill shortage gap, if given the right technical skills needed by the industry.</h2>
        <p>
          Rework Academy gives individuals with no tech skill a high-level tech skill to fill the obvious technical skill shortage in the fast-rising technology industry.
          We are developing local talents from zero skill to advanced employable skill. Best of all, with our flexible monthly payment plan, you pay as you learn.
        </p>
      </div>
    </div>
  );
}

export default AboutSect2;
