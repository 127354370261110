import "../assets/styles/Home.css";
import Banner from "../components/home/Banner";
import HomeFocus from "../components/home/HomeFocus";
import HomeSection1 from "../components/home/HomeSection1";
import HomeSection2 from "../components/home/HomeSection2";
import HomeSection3 from "../components/home/HomeSection3";
import HomeSection4 from "../components/home/HomeSection4";
import HomeSection5 from "../components/home/HomeSection5";
import HomeSection6 from "../components/home/HomeSection6";
import HomeSection7 from "../components/home/HomeSection7";
import HomeSection9 from "../components/home/HomeSection9";

function Home() {
  return (
    <div className="home_page">
      <Banner />
      <HomeFocus />
      <HomeSection1 />
      <HomeSection2 />
      <HomeSection3 />
      <HomeSection4 />
      <HomeSection5 />
      <HomeSection6 />
      <HomeSection7 />
      <HomeSection9 />
    </div>
  );
}

export default Home;
