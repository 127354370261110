import AboutBanner from "../components/about/AboutBanner";
import "../assets/styles/About.css";
import AboutSect1 from "../components/about/AboutSect1";
import AboutSect2 from "../components/about/AboutSect2";
import WhyChooseUs from "../components/about/WhyChooseUs";
import AboutSect5 from "../components/home/HomeSection9";
import HomeFocus from "../components/home/HomeFocus";

function About() {
  return (
    <div className="aboout_page_container">
      <AboutBanner />
      <HomeFocus />
      <AboutSect1 />
      <AboutSect2 />
      <WhyChooseUs />
      <AboutSect5 />
    </div>
  );
}

export default About;
