import { IoCode, IoBarChartOutline, IoPeopleOutline, IoLaptopOutline } from "react-icons/io5";
import { TbCertificate } from "react-icons/tb";
import { GoLocation, GoCodeReview, GoFileCode } from "react-icons/go";

export const PricingGrid = () => {
  return (
    <div className="P_sect1-grid-card">
      <div className="P_sect1-flexed">
        <p>
          <IoBarChartOutline className="P_sect1-flexed-icon" /> Required Skill
        </p>
        <p>None</p>
      </div>
      <div className="P_sect1-flexed">
        <p>
          <TbCertificate className="P_sect1-flexed-icon" /> Certificate
        </p>
        <p>Yes</p>
      </div>
      <div className="P_sect1-flexed">
        <p>
          <GoLocation className="P_sect1-flexed-icon" /> Location
        </p>
        <p>Online(Self-paced)</p>
      </div>
      <div className="P_sect1-flexed">
        <p>
          <IoCode className="P_sect1-flexed-icon" /> Project Based
        </p>
        <p>Yes</p>
      </div>
      <div className="P_sect1-flexed">
        <p>
          <IoLaptopOutline className="P_sect1-flexed-icon" /> Mentor Live Sections
        </p>
        <p>Yes</p>
      </div>
      <div className="P_sect1-flexed">
        <p>
          <IoPeopleOutline className="P_sect1-flexed-icon" /> Peer Pairing
        </p>
        <p>Yes</p>
      </div>
      <div className="P_sect1-flexed">
        <p>
          <GoCodeReview className="P_sect1-flexed-icon" /> Project Review
        </p>
        <p>Yes</p>
      </div>
      <div className="P_sect1-flexed">
        <p>
          <GoFileCode className="P_sect1-flexed-icon" /> Portfolio Review
        </p>
        <p>Yes</p>
      </div>
    </div>
  );
};
