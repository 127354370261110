import { FaMoneyBillWave } from "react-icons/fa";
import { IoTimeOutline, IoBarChartOutline } from "react-icons/io5";
import { TbCertificate } from "react-icons/tb";
import { GoLocation } from "react-icons/go";
import { TfiMoney } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { useState } from "react";

function CDSection1Right() {
  const [togglePrice, setTogglePrice] = useState(true);

  return (
    <div className="CD_s1--right">
      <div className="CD_s1--right-cont">
        <div className="CD_s1--right-cont-header">
          <button onClick={() => setTogglePrice((prev) => (prev = true))} className={`CD_s1--right-btns ${togglePrice ? "active" : ""}`}>
            USD
          </button>
          <button onClick={() => setTogglePrice((prev) => (prev = false))} className={`CD_s1--right-btns ${!togglePrice ? "active" : ""}`}>
            NGN
          </button>
        </div>
        <div className="CD_s1--right-flex">
          <div className="flexed1">
            <FaMoneyBillWave />
            <h4>Total Payable Fee</h4>
          </div>
          <p className="CD_s1-price">{togglePrice ? "$300" : "₦300,000"}</p>
        </div>
        <div className="CD_s1--right-flex">
          <div className="flexed1">
            <TfiMoney />
            <h4>Monthly Installments</h4>
          </div>
          <p>{togglePrice ? "$25" : "₦25,000"}</p>
        </div>
        <div className="CD_s1--right-flex">
          <div className="flexed1">
            <IoTimeOutline />
            <h4>Duration</h4>
          </div>
          <p>12 months</p>
        </div>
        <div className="CD_s1--right-flex">
          <div className="flexed1">
            <IoBarChartOutline />
            <h4>Required Skill</h4>
          </div>
          <p>Beginner</p>
        </div>
        <div className="CD_s1--right-flex">
          <div className="flexed1">
            <TbCertificate />
            <h4>Certificate</h4>
          </div>
          <p>Yes</p>
        </div>
        <div className="CD_s1--right-flex">
          <div className="flexed1">
            <GoLocation />
            <h4>Location</h4>
          </div>
          <p>Online / Self-paced</p>
        </div>
        <div className="CD_s1--right-flex">
          <Link to="/pricing" className="btns-outline-primary">
            View Fee Options
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CDSection1Right;
