function CDBanner() {
  return (
    <>
      <div className="navSpace"></div>
      <div className="CD_banner">
        <div className="CD--content container">
          <h2>Full Stack Web development with Node</h2>
          <p>Self-Paced Online Classes, your path to becoming an experienced Full-stack web developer from anywhere.</p>
          <a href="https://register.reworkacademy.co/" className="btns-primary">
            Apply Now
          </a>
          <h5>
            Next Start Date: <span>Monday, 29th April 2024</span>
          </h5>
        </div>
      </div>
    </>
  );
}

export default CDBanner;
