import React, { useState } from "react";

import { PricingGrid } from "./PricingGrid";

function PricingSection1() {
  const [currencyState, setCurrencyState] = useState(true);
  return (
    <>
      <div className="P__section1">
        <div className="P__Sect1-content container">
          <div className="p__sect1-btns">
            <button onClick={() => setCurrencyState(true)} className={`p__sect1-btn ${currencyState === true ? "activeCurrency" : ""}`}>
              NGN
            </button>
            <button onClick={() => setCurrencyState(false)} className={`p__sect1-btn ${currencyState === false ? "activeCurrency" : ""}`}>
              USD
            </button>
          </div>
          <div className="p__sect1-grids">
            <div className="P__sect1-grid P__sect1-grid1">
              {currencyState ? <h2>₦25,000 / Monthly</h2> : <h2>$25 / Monthly</h2>}
              <p>Opt in for our monthly payment plan to pay for each month you learn.</p>
              <div className="P__sect1-grid1-text2">
                <h2>Monthly Payment Plan</h2>
                <p>Choose our monthly payment plan, to pay as you learn.</p>
              </div>
              <PricingGrid />
            </div>
            <div className="P__sect1-grid P__sect1-grid1">
              {currencyState ? <h2>₦71,250 / Quarterly</h2> : <h2>$71.25 / Quarterly</h2>}
              <p>Opt in for our quarterly payment plan to pay for in in 3 months interval.</p>
              <div className="P__sect1-grid1-text2">
                <h2>Quarterly Payment Plan</h2>
                <p>
                  Make payments quarterly, and get a <strong>5%</strong> discount off the overall fee
                </p>
              </div>
              <PricingGrid />
            </div>
            <div className="P__sect1-grid P__sect1-grid1">
              {currencyState ? <h2>₦270,000 / Year</h2> : <h2>$270 / Year</h2>}
              <p>Opt in for a one year payment plan, to pay for a full year.</p>
              <div className="P__sect1-grid1-text2">
                <h2>Upfront Payment</h2>
                <p>
                  Make an upfront payment and get a <strong>10%</strong> discount off the overall fee
                </p>
              </div>
              <PricingGrid />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingSection1;
