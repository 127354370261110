import React from "react";
import { Link } from "react-router-dom";

function CDSection4() {
  return (
    <div className="cd_section-content">
      <div className="CD--overview">
        <h2>Payment Options</h2>
        <p className="CD--overview-text1">Tuition payment is based on flexible monthly payment agreement during the 12 months training period</p>
        <Link to="/pricing" className="btns-outline-primary">
          View Pricing
        </Link>
      </div>
    </div>
  );
}

export default CDSection4;
