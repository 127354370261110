import checkbox from "../../assets/images/checkbox.png";

function CDSection2() {
  return (
    <div className="cd_section-content">
      <div className="CD--overview">
        <h2>Curriculum</h2>
        <p className="CD--overview-text1">
          Our curriculum is structured to take you from zero skill level in coding to advanced skill. Our Full Stack web development program will enable students to
          create complete web application covering front-end, data layer, and back-end. Also Model Database, Creating Restful API writing well structured and effective
          codes through proper understanding of Data Structure and Algorithms
        </p>
      </div>
      <div className="cd_s2--list">
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>HTML</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>CSS</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>JavaScript</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>Bootstrap</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>SASS/SCSS</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>UI/UX Design</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>MySQL</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>Express JS</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>NODE JS</p>
        </div>
        <div className="cd_s2--list-item flexed1">
          <img src={checkbox} alt="Checkbox" />
          <p>Cloud / Deployment</p>
        </div>
      </div>
    </div>
  );
}

export default CDSection2;
