import img from "../../assets/images/banner-img.png";

function Banner() {
  return (
    <div className="banner">
      <div className="banner-content container">
        <div className="banner-left">
          <h1 className="banner-left-text">
            <span>Become </span> a Highly Skilled Tech Talent.
          </h1>
          <p>At Rework Academy, We are developing the top 10% of tech talents, turning individuals without tech skills into world-class Tech Talents.</p>
          <a href="https://register.reworkacademy.co/">Apply now</a>
        </div>
        <div className="banner-right">
          <img src={img} alt="Learning Online" />
        </div>
      </div>
    </div>
  );
}

export default Banner;
